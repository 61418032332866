@import "src/styles/mixins";
@import "src/styles/shortcuts";

.Block {
  margin-bottom: 96px;
  position: relative;
  @include beMobile() {
    margin-bottom: 64px;
    & > div {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .BlockContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;
    @include beMobile() {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 32px;
      padding: 0 5px;
    }


    &Title {
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
      color: $colorTitle;
      margin: 0 0 0 5px;
      @include beMobile() {
        width: 80%;
        font-weight: 800;
        font-size: 36px;
        line-height: 44px;
        color: $colorTitle;
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    &Link {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $mainPageRed;
      margin-top: 14px;
      margin-right: 5px;
      cursor: pointer;
      transition: .2s ease-in-out all;
      @include beMobile() {
        margin-right: 0;
      }

      &:hover {
        color: $Red_one;
      }

      &:active {
        color: $mainPageRed;
      }

      @include beMobile() {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $mainPageRed;
        margin-top: 14px;

        &:hover {
          color: $mainPageRed;
        }
      }

      &[data-disabled="true"] {
        color: $BaG_gray_five;
        cursor: default;
      }
    }

    &Child {
      position: relative;
      margin-right: 5px;
      margin-left: 5px;

      &Bonus {
        position: relative;
      }
    }

    &ChildNM {
      position: relative;
    }

    &Banner {
      position: relative;
    }
  }
}
