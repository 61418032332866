@import "./src/styles/mixins";
@import "./src/styles/fonts";

.CustomDotActive {
  width: 12px;
  height: 12px;
  background: $BaG_gray_six;
  border: 1px solid $BaG_gray_five;
  margin: 0 4px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.CustomDotInactive {
  width: 12px;
  height: 12px;
  background: $BaG_gray_one;
  border: 1px solid $BaG_gray_two;
  margin: 0 4px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.CustomDotListClass {
  position: relative;
  margin-top: 30px;
}

.CustomLeftArrow {
  cursor: pointer;
  // margin-right: 32px;
  position: absolute;
  top: 40%;
  left: -15px;
  width: 40px;
  height: 40px;
  transform: rotate(180deg);
  // visibility: hidden;
  // opacity: 0;
  transition: 0.2s ease-in-out;
}

.CustomRightArrow {
  cursor: pointer;
  // margin-left: 32px;
  position: absolute;
  top: 40%;
  right: -15px;
  width: 40px;
  height: 40px;
  // visibility: hidden;
  // opacity: 0;
  transition: 0.2s ease-in-out;
}

.CustomButtonGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  &_Container {
    display: flex;
    align-items: center;
  }
}

.CarouselContainer:hover .CustomLeftArrow {
  visibility: visible;
  opacity: 1;
}

.CarouselContainer:hover .CustomRightArrow {
  visibility: visible;
  opacity: 1;
}

// .CarouselContainer > div > ul > li {
//   width: auto !important;
// }
